<template>
  <div class="box" >
    <div class="image" v-for="(image, id) in images" :key="id" v-bind:class="{ active: id == index, inactive: id != index }" :style="'background-image: url(' + getImageUrl(image, id) + ');' + style" ></div>
  </div>
</template>

<script>

export default {
  props: {
    images: {
      required: true,
      type: Array,
    },
    intervall: {
      type: Number,
      default: 0
    },
    speed: {
      type: Number,
      default: 1
    }
  },
  data: () => ({
    index: 0
  }),
  computed: {
    style() {
      return 'transition: opacity ' + this.speed + 's ease-out;'
    },
  },
  methods: {
    getImageUrl(image, id) {
      if (id <= this.index + 1 || image.done) {
        image.done = true
        return image.url
      }

      return ''
    },
  },
  mounted() {
    if (this.intervall > 0) {
      setInterval(() => {
        this.index = this.index + 1 < this.images.length ? this.index + 1 : 0
      }, this.intervall)
    }
  },
}
</script>

<style scoped lang="scss">
.box {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100vw * (2 / 3));
  max-height: 100vh;
}

.image {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  height: 100%;
  width: 100%;
}

.image.active {
  opacity: 1;
}

.image.inactive {
  opacity: 0;
}
</style>
